<template>
  <div class="report">
    <div class="page-header">
      <h1>{{ $t('Report') }} > {{$t('Realtime Report') }} </h1>
    </div>
    <h2 class="subheader">{{ $t('Realtime Report') }}</h2>
    <div class="charts-cards">
      <div class="chart-inner">
        <h2>{{ $t('Total Orders') }}</h2>
        <TotalOrders />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import TotalOrders from '@/components/dashboard/TotalOrders.vue';

export default {
  name: 'Report',
  components: {
    TotalOrders,
  },
  data(){
    return {
      'dateSelect': 'Daily',
    };
  },
  computed: mapState({
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style scoped lang="scss">
.subheader{
  margin-top: 30px;
  margin-bottom: 30px;
}
.charts-cards{
  display: flex;
  flex-wrap: wrap;
}
.chart-inner{
  -webkit-box-flex: 0;
  -ms-flex: 0 0 80%;
  flex: 0 0 80%;
  margin-right: 10px;
  margin-top: 50px;
  background-color: white;
  padding: 20px;
}
</style>
